.collapse-enter {
    opacity: 0;
    height: 0;
    transition: opacity 300ms ease, height 300ms ease;
  }
  
  .collapse-enter-active {
    opacity: 1;
    height: auto;
  }
  
  .collapse-exit {
    opacity: 1;
    height: auto;
    transition: opacity 300ms ease, height 300ms ease;
  }
  
  .collapse-exit-active {
    opacity: 0;
    height: 0;
  }
  